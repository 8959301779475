.react-cookie-law-select-pane {
  display: none !important;
}
.react-cookie-law-dialog {
  background: rgb(53, 53, 53) !important;
  color: white !important;
  padding: 5px !important;
  position: fixed;
}
.react-cookie-law-policy {
  display: none !important;
}
.react-cookie-law-accept-btn {
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  color: black;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px !important;
  background: #00efda !important;
  margin: 5px !important;
  min-width: 1px !important;
}
.react-cookie-law-msg {
  color: white !important;
  font-family: "Din Pro Condensed", "Open Sans", sans-serif;
  font-size: 1rem !important;
  font-weight: 300 !important;
  line-height: 1.7 !important;
  width: 100% !important;
}
.react-cookie-law-container {
  display: flex !important;
  align-items: center;
  justify-content: space-between !important;
  width: 100% !important;
  margin: 0px;
  max-width: 100% !important;
}
.playerControls__slider {
  width: 100%;
}
.video_grid {
  padding: 15px;
}
@media screen and (max-width: 450px) {
  .video_grid {
    padding: 5px !important;
  }
  .MuiSvgIcon-fontSizeLarge {
    font-size: 1.5rem !important;
  }
  .MuiIconButton-root {
    padding: 2px !important;
  }
  .duration_label {
    margin: 2px !important;
    padding: 2px !important;
  }
}
.pt-10 {
  padding-top: 10px;
}
.datos::placeholder {
  color: #b5b5b5 !important;
}
