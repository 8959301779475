//
// Custom
//

@font-face {
  font-family: "Din Pro Condensed";
  src: url("/fonts/DinProCondensed-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Din Pro Condensed";
  src: url("/fonts/DinProCondensed-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Din Pro Condensed";
  src: url("/fonts/DinProCondensed-Bold.otf");
  font-weight: 600;
}

// --- UTILITIES ---

// :root {
//   touch-action: pan-x pan-y;
//   height: 100%
// }

* {
  font-family: "Din Pro Condensed", "Open Sans", sans-serif;
}
body {
  color: $default;
  background-color: $white;
  // touch-action: none;
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.uppercase {
  text-transform: uppercase;
}

.fw-600 {
  font-weight: 600;
}

.link {
  cursor: pointer;
}

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.bg-light-grey {
  background-color: #fffffa;
}

.color-primary {
  color: $primary;
}

.dropdown-menu {
  min-width: 10rem;
}

.my-half1 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.min-100vh {
  min-height: calc(100vh - 56px);
}

.min-width-200 {
  min-width: 200px;
}
.btn-black {
  color: $white;
  background: $black;
}

.color-secondary {
  color: $secondary;
}

.input-group-text,
.form-control {
  border: 1px solid #fff;
}
.bg-primary {
  background-color: $primary;
}

.txt-white {
  color: $white;
}

.txt-black {
  color: $black;
}

.bg-linear-gradient {
  background: linear-gradient($primary, $secondary);
}

.p-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 126px;
  transition: all 0.2s linear;

  @media screen and (min-width: 768px) {
    top: 74px;
    margin-bottom: 66px;
  }

  @media screen and (min-width: 992px) {
    top: 126px;
    margin-bottom: inherit;
  }
}
.p-sticky-1 {
  position: sticky;
  position: -webkit-sticky;
  top: 74px;
  transition: all 0.2s linear;

  @media screen and (min-width: 768px) {
    margin-bottom: 42px;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: inherit;
  }
}

p.link {
  color: $primary;
  cursor: pointer;

  &:hover {
    color: darken($link-color, 15%);
  }

  &.inline {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
  }
}

.info-text-sm {
  font-size: 10px;
  font-weight: 500;
  font-family: "Helvetica", sans-serif;

  &.muted {
    opacity: 0.6;
  }
}

.info-text-md {
  font-size: 12px;
  font-weight: 500;
  font-family: "Helvetica", sans-serif;
}

.vertical-center {
  align-items: center;
}

.border-radius-10 {
  border-radius: 10px !important;

  div iframe {
    border-radius: 10px !important;
  }
}

.border-radius-10-top {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.medium-font {
  font-size: 14px;
  line-height: 16px;
}

.capitalize{
  text-transform: capitalize;
}

.half-width {
  width: 50%;
}
.full-width {
  width: 100%;

  &.btn-group {
    .btn {
      width: 34%;
      max-height: 34px;
      padding: 0.4rem 0.75rem;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
      &.half-width {
        width: 50%;
      }
    }
  }

  .action.btn {
    width: 50%;
    padding: 8px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  }
}
.hz-padded {
  padding: 0 15px;
}

.dropdown-menu {
  width: calc(100% - 30px);
}

.lh-10 {
  line-height: 10px;
}

.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.no-mobile {
  display: none;
}
#rdp-input-group-birthday {
  .input-group-append {
    position: absolute;
    right: 0;
    max-height: 34px;

    span {
      padding: 0.77rem 0.75rem;
      line-height: 0.8rem;
    }
  }
}
#rdp-form-control-birthday {
  border-radius: 6px;
  color: $black;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}
.separator-skills {
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  margin: 0 10px;
  transform: translateY(-4px);
}

@media screen and (min-width: 768px) {
  .no-desktop {
    display: none;
  }
  .no-mobile {
    display: block;
  }
}
.react-date-picker__inputGroup__leadingZero{
  display: none;
}

.alert-warning {
  color: #fff;
  background-color: #757575;
  border-color: #757575;
  padding: 5px 10px;
}

.btn-label {
  font-size: $font-size-sm;
  color: $black;
  font-weight: 400;
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: $black;
  background-color: $primary;
  border-color: $white;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-11 {
  font-size: 11px;
}

.btn-group .btn {
  font-size: $font-size-sm;
  color: $black;
  font-weight: 400;
}

.footer {
  padding: 1rem 0 3.5rem;
  background: $white;

  @media screen and (min-width: 768px) {
    padding: 1rem 0 0 0;
  }
}

// --- END UTILITIES ---

.navbar-nav {
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 15px;
}

.navbar {
  padding: 0px;
  z-index: 99999;
}

.main-content {
  padding-top: 56px;
  padding-bottom: 50px;
  min-height: calc(100vh - 56px);

  .container-fluid {
    @media screen and (min-width: 768px) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}

.main-content .navbar-top {
  @media screen and (min-width: 768px) {
    margin-left: 80px;
    width: calc(100% - 80px);
    max-width: calc(100vw - 80px);
    z-index: 9999;
  }
}

.navbar-vertical .navbar-nav {
  margin-left: 0;
  margin-right: 0;

  .nav-link {
    transform: translateY(8px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;

    &.add-post-button {
      margin: 0 auto;
      padding: 0px;
      transform: translateY(5px);
    }

    i {
      min-width: 2.25rem;
      font-size: 1.4375rem;
      line-height: 2.1rem;
      margin-bottom: 5px;
    }

    &.active:before {
      content: "";
      position: absolute;
      left: calc(50% - 5px);
      right: 50%;
      height: 10px;
      width: 10px;
      background: $primary;
      border-radius: 5px;
      top: -5px;
      bottom: auto;
      border-top: none !important;
      border-left: none !important;
      box-shadow: 0 0 3px #00000073;

      @media screen and (min-width: 768px) {
        border-right: none;
        top: calc(0% - 5px) !important;
        left: calc(50% + -5px) !important;
        border-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 100px 0;
    height: 100vh;
    display: flex;
    margin-left: 0 !important;
    margin-right: 0 !important;
    left: 0;
    border-width: 0 1px 0 0;
  }
}

#navbar-main {
  background: $primary;

  &.no-topbar-margin {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }
  //box-shadow: 0 0 6px #00000073;
}

#sidenav-main {
  background-image: url("/backgrounds/menu-bg.svg");
  box-shadow: none !important;
  @media (min-width: 768px) {
    max-width: 80px;
    padding: 0;
    overflow: visible;
  }
}

.navbar-vertical.navbar-expand-md.fixed-left + .main-content {
  @media (min-width: 768px) {
    margin-left: 80px;
  }
}

.main-logo-wrapper {
  margin: 0 auto;
}
.main-logo {
  max-width: 130px;
  margin: 5px 10px;
}

.top-bar {
  right: 0px;
  position: absolute;
  padding: 0 10px 0 0;
  flex-direction: row;
  justify-content: flex-end;
  width: 50px;
}

.menu-hamburger img {
  max-width: 38px;
  padding: 5px;
  transform: translate(-70%, 0);
}

.no-styles {
  padding: 0;
  border: none;
  background: transparent;
  border-radius: 15%;
}

.dropdown-header {
  color: #00ada9;
  padding-bottom: 0;
  padding-top: 0;
  font-size: 12px;
}

.fav_team-dropdown-item {
  padding-left: 24px !important;
}

.card-profile-image {
  img {
    border: 5px solid #00efda;
    height: 150px;
    width: 150px;
    object-fit: cover;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 15% !important;
    box-shadow: 0 0 20px #0000006b;

    &:hover {
      transform: none;
    }

    &:after {
      content: "";
      box-shadow: none;
      border: none;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
    }

    &.small {
      max-width: 55px;
      max-height: 55px;
      border: 3px solid $primary;
      position: relative;
      transform: none;
      left: 0%;
    }

    &.smaller {
      max-width: 34px;
      margin: 2px;
      border: 3px solid $primary;
      position: relative;
      transform: none;
      left: 0%;
    }
  }
}

.menu-tab-info {
  padding: 0.575rem 1rem !important;

  span {
    font-size: 15px;
    font-weight: 600;
    color: #000;
  }
}

.navbar-nav .dropdown-menu {
  position: fixed !important;
  right: 0;
  width: 100vw;
  left: auto !important;
  border-radius: 0px;
  max-height: 500px !important;
  transform: translate(0, 54px) !important;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);

  @media screen and (min-width: 768px) {
    width: 300px;
    height: 100vh;
    max-height: 100vh !important;
    transform: translate(0, 56px) !important;
  }
}

.navbar .dropdown-menu-right:before {
  right: 29px;
}

.shadow,
.card-profile-image img {
  box-shadow: 0 0 1rem 0 rgba(136, 152, 170, 0.15) !important;
}

.filter-button {
  padding-right: 26px !important;
}

.add-post-button {
  font-size: 44px !important;
  color: $white !important;
  text-shadow: 0 0 13px #ffffff9c;
  font-weight: 500;
  background-color: $primary;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 50px;
  width: 60px;
  height: 60px;
  position: relative;

  &.active:before {
    background: transparent !important;
    box-shadow: none !important;

    @media screen and (min-width: 768px) {
      border-right: none !important;
      border-top: none !important;
    }
  }

  @media (min-width: 768px) {
    float: none;
    margin: 0 auto;
    transform: translate(0px, 0px);
  }
}

#navbar-main .container-fluid {
  padding: 9px;
  max-height: 56px;
}

#sidenav-main .container-fluid .navbar-nav {
  background: $white;
  box-shadow: 0 0 5px #00000057;

  .nav-item {
    width: 20vw !important;

    @media screen and (min-width: 768px) {
      width: 80px !important;
      z-index: 99999;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 60px 0;
    height: 100vh;
  }
}

// MENU ICONS

.menu-icon {
  min-width: 2rem;
  font-size: 1.4375rem;
  line-height: 2.1rem;
  margin-bottom: 5px;
  min-height: 2rem;

  &.add {
    background-image: url("/apple-icon.png");
    background-color: $primary;
    border-radius: 50%;
    margin-bottom: 0px;
    border: 5px solid $primary;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 94%;
    height: 94%;
  }
  &.user {
    background-image: url("/icons/user.svg");
  }
  &.challenge {
    background-image: url("/icons/ball.svg");
  }
  &.search {
    background-image: url("/icons/search.svg");
  }
  &.home {
    background-image: url("/icons/home.svg");
  }
}

.button {
  &.discovered {
    margin-top: 8px;
    padding: 5px 20px;
    color: $black;
    height: 30px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    letter-spacing: 1px;
    font-weight: 700;

    &.small {
      margin-top: 0px;
      padding: 5px 10px;
      font-size: 12px;
      width: 110px;
      height: 28px;
    }
  }
  &.small {
    margin-top: 0px;
    margin-left: 2px;
    margin-right: 2px;
    padding: 3px 7px;
    font-size: 14px;
    height: auto;
  }
}

.username {
  transform: translateY(-240%);
}

.profile-description {
  font-size: 12px;
  margin-bottom: 0;
  font-family: "Helvetica", sans-serif;
  line-height: 15px;
}

.profile-stats {
  font-size: 11px;
  padding-top: 0px;
  margin-top: -5px;
  font-weight: 500;
  margin-bottom: 0;
  color: #00ada9;
}

.profile-stats-bigger {
  font-size: 12px;
  padding-top: 2px;
  font-weight: 500;
  margin-bottom: 0;
  color: $secondary;
}

.card-profile-stats > div {
  text-align: center;
  margin-right: 1%;
  padding: 0px;
  width: 33%;
}

.card-profile-stats > .secondary {
  text-align: center;
  margin: 0 auto;
  padding: 3px;
  width: 30%;
  border: none;
  border-right: 1px solid #ddd;
  border-radius: 0;

  &:nth-of-type(3) {
    border-right: none;
  }
}

.card-profile-stats-second {
  width: calc(100%);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  padding: 15px 5px;
  text-align: center;
}

.card-profile-stats {
  padding: 0.5rem 0 0;

  &.shadow {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.15), 0 0px 3px rgba(0, 0, 0, 0.25) !important;
  }
}

.profile-stats-value-1 {
  color: $black;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 2px;
}

.profile-stats-value {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 2px;
}

.card-profile-stats > div .heading {
  line-height: 1.1rem;
  color: $primary;
}
.card-profile-stats > div .description {
  font-size: 13px;
}

.profile-card-icon {
  width: 35px;
}

.profile-card-icon-small {
  width: 25px;
}

.card-profile {
  // touch-action:none;
  @media screen and (min-width: 992px) {
    max-height: 90vh;
    overflow-y: scroll;
  }
}

.overflow-visible.overflow-visible {
  @media screen and (min-width: 992px) {
    max-height: 100%;
    overflow-y: inherit;
  }
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  .card-profile {
    width: 50%;
    height: 248px;

    @media screen and (min-width: 768px) {
      width: 29%;
    }

    @media screen and (min-width: 992px) {
      width: 55%;
    }
  }

  &.user-list {
    .card-profile {
      width: 50%;
      height: 248px;

      @media screen and (min-width: 768px) {
        width: 29%;
      }
    }
  }
}

.card-profile.stats {
  padding: 5px;
  width: 50%;
  border-radius: 0;
  border: none;
  &.b-right {
    border-right: 1px solid #010101 !important;
  }
  .profile-stats {
    color: $white;
  }
}

.post-type-select {
  letter-spacing: 1px;
  position: relative;
  transition: all 0.3s linear;
  line-height: 20px;
  cursor: pointer;

  &.active {
    font-weight: 900;
    border-bottom: 2px solid $black;
    transition: all 0.3s linear;
  }
}

.posts-container {
  border-radius: 10px;
  background: $white;
}

.agente-contrato {
  width: 50% !important;
  margin: 0 !important;
}

// VIDEO COMPONENT

.youtube-video-wrapper {
  position: relative;
}

.youtube-video {
  height: 250px !important;
  width: auto !important;
  max-width: 100%;
  background: #000;
  // border-top-right-radius: 10px;
  // border-top-left-radius: 10px;

  video::-webkit-media-controls-timeline {
    display: none;
  }

  div {
    iframe::-webkit-media-controls-timeline {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    height: 350px !important;
    width: auto !important;
  }

  @media screen and (min-width: 901px) {
    height: 450px !important;
    width: auto !important;
  }
}

.youtube-video-fs{
  height: 100%;
  width: 100%;
  .youtube-video {
    height: 100% !important;
  }
}


.post-wrapper {
  width: 100%;

  .row {
    max-width: 100%;
    margin: 0;
  }
}

.menu-dots img {
  max-width: 34px;
  padding: 5px;
  transform: translate(-30%, 0);
}

.skill-cloud {
  &.small {
    .btn {
      font-size: 12px;
      margin-left: 3px;
      margin-right: 3px;
    }
  }
  .btn {
    margin-left: 3px;
    margin-right: 3px;
  }
  .btn + .btn {
    margin-left: 3px;
    margin-right: 3px;
  }
}

.video-menu-toggle,
.video-menu-toggle:focus,
.video-menu-toggle:active,
.video-menu-toggle:hover {
  background: transparent !important;
  border: none;
  box-shadow: none !important;
  padding: 0;
  margin: 0;
  margin-right: 0 !important;
}

.video-menu {
  padding: 10px 5px;
  min-width: 100px;
}

//--- RATING ---
.rating {
  max-width: 50%;
  height: 28px;
  min-width: 142px;
  margin-right: 5px;

  .icon {
    width: 28px;
    height: 28px;
    padding: 2px;
    line-height: 28px;
  }
}

.rating-avg {
  width: 36px;
  margin-right: 10px;
  height: 36px;
  padding: 8px 5px;
  border-radius: 20px;
  border: 2px solid $secondary;
  transform: translateY(-5px);
  font-family: "Helvetica", sans-serif;

  .h4 {
    min-width: 50%;
    line-height: 16px;

    &.smaller {
      font-size: 12px;
    }
  }
}

.rating-wrapper {
  position: relative;
  flex-direction: column;
  margin-bottom: 2px;
}

.flex-vertical {
  flex-direction: column;
}

.num-of-ratings {
  margin-bottom: 0;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: "Din Pro Condensed", "Open Sans", sans-serif;

  @media screen and (min-width: 768px) {
    top: 70%;
  }
}

//--- END RATING ---

//------------------------------------------------------------------------------------------

//--- POSTS FILTER ---

.dropdown .dropdown-toggle {
  text-align: left;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: normal;
  padding: 0.4rem 0.75rem;
  height: 34px;
  color: $black;

  @media screen and (max-width: 768px) {
    &.mobile-bigger-dropdown {
      height: 43px;
      padding: 0.625rem 0.75rem;
    }
  }
}

.form-control:focus {
  border-color: #e6e6e6;
  color: #000;
}

.posts-filter-modal {
  z-index: 99999;
  transform: translate(0, 60px) !important;

  .dropdown .dropdown-toggle {
    text-align: left;
    font-size: 12px;
    line-height: 14px;
  }

  .input {
    font-size: 12px;
    line-height: 14px;
  }
  .form-control {
    font-size: 12px;
    line-height: 14px;
    max-height: 36px;
  }

  .full-width.btn-group .btn {
    font-size: 12px;
    line-height: 14px;
  }

  .custom-control-label {
    font-size: 12px !important;
    line-height: 14px;
    font-weight: 600;
  }

  .custom-control-label::before,
  .custom-control-label::after {
    top: -0.1rem;
    width: 1rem;
    height: 1rem;
    left: -1.4rem;
  }
  .custom-control-input {
    font-size: 12px;
    line-height: 24px;
  }

  .custom-control.custom-checkbox {
    padding-left: 0;
  }
}

.form-control-alternative {
  color: $black;
  height: 34px;
  border: 1px solid #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  &.textarea {
    max-height: none;
  }
  @media screen and (max-width: 768px) {
    &.mobile-bigger-dropdown {
      height: 43px;
      padding: 0.3rem 0.75rem;
      border: none;
    }
  }
}

::-webkit-input-placeholder, ::-ms-input-placeholder, ::placeholder {
  color: #858688 !important;
}

.filter-button.mobile-bigger-dropdown {
  @media screen and (max-width: 768px) {
    &.mobile-bigger-dropdown {
      height: 43px;
      padding: 0.3rem 0.75rem;
      border: none;
    }
  }
}

.post-type-select-filter {
  letter-spacing: 1px;
  line-height: 20px;
  position: relative;
  color: $primary;
  cursor: pointer;

  &.active {
    border-bottom: 2px solid $primary;
  }
}

//--- END POSTS FILTER ---

//--- USER FILTER ---

.dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: calc(50% - 2px);
}

.dropdown-menu .dropdown-item {
  padding: 0.2rem 1rem;
}

.user-filter-data {
  .dropdown .dropdown-toggle {
    text-align: left;
    font-size: 12px;
    line-height: 14px;
  }

  .input {
    font-size: 12px;
    line-height: 14px;
  }
  .form-control {
    font-size: 12px;
    line-height: 14px;
    max-height: 36px;
  }

  .full-width.btn-group .btn {
    font-size: 12px;
    line-height: 14px;
  }
  .custom-control-label {
    font-size: 12px !important;
    line-height: 14px;
    font-weight: 600;
  }

  .custom-control-label::before,
  .custom-control-label::after {
    top: -0.1rem;
    width: 1rem;
    height: 1rem;
    left: -1.4rem;
  }
  .custom-control-input {
    font-size: 12px;
    line-height: 24px;
  }

  .custom-control.custom-checkbox {
    padding-left: 1.5rem;
  }
}

.custom-control-alternative .custom-control-label::before {
  top: 0.12rem;
  box-shadow: 0 1px 5px rgba(50, 50, 93, 0.25), 0 2px 0 rgba(0, 0, 0, 0.02);
}

//--- END USER FILTER ---

//--- SEARCH ---

.search-bar {
  margin-right: 5px;
  .search-input {
    &:focus {
      border-color: transparent;
    }
  }
}

.search-icon {
  width: 1.2rem;
  height: 1.2rem;
}

.input-group-prepend {
  height: 34px;
}

.search-input {
  height: 34px;
}

.card-profile-image .rounded-circle.smaller {
  width: 34px;
  height: 34px;
  margin: 0 2px;
  border: 3px solid;
  position: relative;
  -webkit-transform: none;
  transform: none;
  left: 0%;
  border-radius: 15% !important;
  text-align: center;
  padding: 9px 0;

  p {
    font-size: 12px;
    font-weight: 600;
  }
}

//--- END SEARCH -------

//--- ADD VIDEO --------

.search-input::placeholder {
  /* Firefox, Chrome, Opera */
  color: $black;
  font-weight: 400;
}

.search-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $black;
  font-weight: 400;
}

.input-group-alternative .form-control::placeholder {
  color: $gray-400;
  font-weight: 400;
}

.input-group-alternative .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $gray-400;
  font-weight: 400;
}

.input-group .form-control {
  color: $black;
}

.video-placeholder {
  height: 200px;
  border: 1px solid #000;
  background: #000;
  color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  @media screen and (min-width: 768px) {
    height: 400px;
  }
}

.skill-btn.active {
  background: $black;
}

//--- END ADD VIDEO ---------

//--- EDIT PROFILE --------------------------------------

.profile-picture-wrapper {
  margin: 0 auto;
  max-width: 108px;
  max-height: 108px;
  position: relative;

  .profile-picture {
    border: 4px solid #00efda;
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(50, 50, 93, 0.31), 0 1px 3px rgba(0, 0, 0, 0.1);
  }
}
.edit-photo {
  padding: 0 10px;
  font-weight: 700;
  color: $primary;
  background: $white;
  border-radius: 5px;
  border: 2px solid $primary;
}

.form-control-alternative::placeholder {
  /* Firefox, Chrome, Opera */
  color: $black;
  font-weight: 400;
}

.form-control-alternative:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $black;
  font-weight: 400;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
.react-calendar__month-view__weekdays {
  display: none !important;
}

.react-calendar__navigation {
  margin-bottom: 8px;
  padding-top: 5px;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 8px;
}

.react-date-picker__calendar {
  z-index: 2;
}

.react-date-picker__calendar.react-date-picker__calendar--open,
.react-date-picker.react-date-picker--open.react-date-picker--enabled.date-input-picker,
.react-date-picker.react-date-picker--closed.react-date-picker--enabled.date-input-picker {
  width: 100%;
}

.react-time-picker__wrapper,
.react-date-picker__wrapper {
  max-height: 34px;
  height: calc(2.75rem + 2px);
  padding: 0rem;
  color: $black;
  border: none;
}

.react-time-picker__inputGroup__input::placeholder,
.react-date-picker__inputGroup__input::placeholder {
  color: $black;
  font-weight: 400;
}

.react-date-picker__calendar .react-calendar {
  border: none;
  border-radius: 0.375rem;
  margin-top: 5px;
  padding: 5px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.react-date-picker__inputGroup__input:invalid {
  background: transparent;
}

.react-date-picker__inputGroup {
  padding: 0 0.75rem;
}

.react-calendar__tile--active {
  background-color: $secondary !important;
  border-radius: 100px !important;
  box-shadow: 0 3px 6px rgba(50, 50, 93, 0.31), 0 1px 3px rgba(0, 0, 0, 0.1);
}

.react-calendar__tile--hasActive {
  background: $secondary !important;
  border-radius: 100px;
  box-shadow: 0 3px 6px rgba(50, 50, 93, 0.31), 0 1px 3px rgba(0, 0, 0, 0.1);
}

.react-calendar__navigation__label,
.react-calendar__navigation button[disabled] {
  background-color: $primary !important;
  color: $black;
  box-shadow: 0 3px 6px rgba(50, 50, 93, 0.31), 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 8px;
  max-height: 40px;
  margin-top: 2px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 100px;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:hover,
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:focus,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button:hover,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}

.react-calendar__tile.react-calendar__month-view__days__day:hover,
.react-calendar__tile.react-calendar__month-view__days__day:focus,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  border-radius: 100px;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #e6e6e6;
}

.react-calendar__tile--now {
  background: transparent;
}

.react-calendar__month-view__days__day--weekend {
  color: $black;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.dropdown-search {
  width: calc(100% - 10px);
  margin-left: 5px;
  margin-bottom: 5px;
  padding: 5px;
  height: 30px;
}

.react-time-picker {
  height: 34px;
  border-radius: 0.375rem;
  width: 100%;
  .react-time-picker__wrapper {
    border: none;
  }
}

.react-date-picker__button__icon,
.react-time-picker__clear-button__icon {
  width: 10px;
}
.react-time-picker__inputGroup {
  padding: 0rem 0.75rem;
  min-width: auto;
  max-width: 100%;
  text-align: left;
}

.react-time-picker__button:enabled:hover .react-time-picker__button__icon,
.react-time-picker__button:enabled:focus .react-time-picker__button__icon,
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: $primary;
}

.filter-range {
  width: 39%;
  color: #000;
}

.filter-range-label {
  line-height: 36px;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 600;
}

.filter-dropdown-row {
  .dropdown {
    width: 50%;

    &:nth-of-type(1) {
      padding-right: 0;
    }
  }
}

.preloader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  left: 0;
  background: #fff;
  background-color: #fff !important;
  z-index: 99999;

  .preloader-icon {
    width: 300px;
  }
}

.time-filter {
  width: 39%;

  input {
    padding: 0 0 0 0.75rem;
  }
}

.hint-wrapper {
  position: relative;
  display: flex;

  .hint {
    position: absolute;
    top: -100%;
    transform: translateY(-5px);
    width: 150px;
    left: 0;
    padding: 5px;
    border-radius: 10px;
    background: $black;
    color: $white;
    font-size: 12px;
    text-align: center;
    z-index: 2;
  }

  .hint-toggle {
    background: $black;
    color: $primary;
    align-self: center;
    border: 2px solid;
    width: 24px;
    font-weight: 800;
    height: 24px;
    line-height: 18px;
    font-size: 14px;
    border-radius: 15px;
  }
}

.dropdown-menu.show {
  position: absolute;
  will-change: transform;
  max-height: 250px;
  overflow-y: scroll;
}

.loading_video {
  color: $primary;
  font-size: 16px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .change-mobile-direction {
    flex-direction: column;
  }
  .extra-bottom-margin-modal {
    margin-bottom: 9.5rem !important;
  }

  .dropdown-menu.show {
    position: absolute;
    will-change: transform;
    max-height: 150px;
    overflow-y: scroll;
  }
}

.error-input {
  border: 1px solid red;
}

.custom-control-label.error-input {
  border: none;

  &::before {
    border: 1px solid red;
  }
}

.user-type-label {
  line-height: 10px;
}

.back-button {
  position: fixed;
  top: 16px;
  z-index: 999999999999;

  img {
    width: 24px;
  }
}

.user-type-filter {
  display: flex;

  @media screen and (min-width: 768px) {
    &.no-desktop {
      display: none !important;
    }
  }
}

.mute-button {
  position: absolute;
  width: 24px;
  z-index: 1;
  transform: translate(10px, -45px);
  // mix-blend-mode: exclusion;
}

.play-button {
  position: absolute;
  width: 100px;
  height: 100px;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  z-index: 1;
  cursor: pointer;
}

.add-to-home-banner {
  background: rgb(0, 173, 171) !important;
  color: white !important;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  z-index: 9;
  padding: 15px 10px;

  @media screen and (max-width: 768px) {
    display: flex;
  }
}
.footer .copyright {
  padding: 10px;
}

.norm-br-radius {
  border-radius: 0.375rem;
}

#rcc-confirm-button {
  background: #00efda !important;
}

.CookieConsent {
  background: rgb(4, 30, 38);
  z-index: 9999999999999999999 !important;
  font-size: 14px !important;

  div {
    margin: 0px !important;
  }
  #rcc-confirm-button {
    margin: 5px !important;
  }
  .cookie-text {
    margin-bottom: 0;
    padding: 5px;
  }
}

.color-white {
  color: $white;
}

.video_grid{
  padding:2px 15px 5px !important;
  
}

.MuiButton-text{
  padding: 2px 8px !important;
}

.MuiTypography-body1{
  font-size: 14px !important;
  line-height: 1 !important;
}

.MuiIconButton-root {
  padding: 0px 4px !important;
}

//--- END EDIT PROFILE -------

/// TEMP!!!!!

// .main-content{
//   padding-top:90px;
// }

// #navbar-main {
//   padding-top: 30px;
// }

// #sidenav-main .container-fluid .navbar-nav {
//   padding-bottom: 3px;
// }
